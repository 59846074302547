import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import "@awsui/global-styles/index.css";
import App from './App';
import { Authenticator } from './auth/AmplifyAuthenticator';


const root = ReactDOMClient.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
   <HashRouter>
         <Authenticator>
        <App/>
        </Authenticator>
    </HashRouter>
  </React.StrictMode>
);
