import { Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { useEffect, useState, useContext, createContext } from 'react';

import amplifyConfig from './amplify.config';

Auth.configure(amplifyConfig);


const AuthContext = createContext({
    user: null,
    authState: 'Loading',
    signOut: async () => { },
});


const useAuth = () => {

    const [user, setUser] = useState(null);
    const [authState, setAuthState] = useState('Loading');
  
    useEffect(() => {

        const getUser = async () => {
            if(authState === 'SignedOut')
                return true
                
            try {
                let userData = await Auth.currentAuthenticatedUser()
                //console.log('currentAuthenticatedUser:', userData)
                setStates(userData, 'SignedIn')
            } catch (err) {
                console.log('currentAuthenticatedUser error:', err)
                setStates(null, 'Loading')
                Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google });
            }

            return true
        }


        const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
            //console.log(`auth event: ${event} \n data: ${JSON.stringify(data)}`)
            switch (event) {
                case 'signIn':
                case 'cognitoHostedUI':
                    getUser()
                    break;
                case 'signOut':
                case 'oAuthSignOut':
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    console.log('Sign in failure', data);

                    break;
                case 'customOAuthState':
                    //console.log('customOAuthState')
                    window.history.replaceState(
                      {},
                      '',
                      `${window.location.origin}${data}`
                    );
                    window.history.go();
                    break;
                default: break;
                }
          
            
        });
        getUser()


        return unsubscribe;
    }, [authState]);



    const signOut = () => Auth.signOut()
        .then(() => {
            setStates(null, 'SignedOut')
        });


    const setStates = (userData, authenticateState) => {
        setUser(userData)
        setAuthState(authenticateState)
    }


    return {
        user,
        authState,
        signOut,
    };
}

export const Authenticator = ({ children }) => {
    const auth = useAuth()

    if (auth.authState !== 'SignedIn')
        return null

    return (

        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => useContext(AuthContext);
