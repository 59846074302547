const amplifyConfig = {
    Auth: {
      // (required)- Amazon Cognito Region
      region: 'us-east-1',
  
      // (optional) - Amazon Cognito User Pool ID
      userPoolId: 'us-east-1_TJOHorJTH',
  
      // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
      userPoolWebClientId: '1i2ashdcdumhoiu21d2hlipp9m',
  
      // (optional) - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,
  
  
      // (optional) - Hosted UI configuration
      oauth: {
        domain: 'poc-imgupload.auth.us-east-1.amazoncognito.com',
        scope: [
          'email',
          'profile',
          'openid',
          'aws.cognito.signin.user.admin'
        ],
        redirectSignIn: window.location.origin,
        redirectSignOut: 'https://accounts.google.com/logout',
        clientId: '1i2ashdcdumhoiu21d2hlipp9m',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
    }
  }

  export default amplifyConfig;